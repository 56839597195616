<template>
  <div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-header">
        <div class="page-wrapper-table-header-left">
          <base-input
            v-model="query"
            type="search"
            class="search"
            prepend-icon="far fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
        </div>
        <div class="page-wrapper-table-header-right">
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            :placeholder="$t('COMMON.PER_PAGE')"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div v-if="resellers.length > 0" class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="resellers"
            @sort-change="sortChange"
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" />
            </div>
            <el-table-column min-width="90" :label="$t('COMMON.PICTURE')">
              <template v-slot="{ row }">
                <div class="reseller-image">
                  <div class="reseller-image-wrapper">
                    <img
                      v-if="row.logo"
                      :src="row.logo"
                      class="avatar rounded-circle"
                    />
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.NAME')"
              prop="name"
              sortable="custom"
              min-width="220"
            />
            <el-table-column
              :label="$t('COMMON.OWNER')"
              prop="reseller.name"
              sortable="custom"
              min-width="220"
            >
              <template v-slot="{ row }">
                <span>
                  {{
                    row.owner
                      ? `${row.owner.lastname} ${row.owner.firstname} - ${row.owner.email}`
                      : null
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.CREATED_AT')"
              prop="created_at"
              sortable="custom"
              min-width="220"
            />
            <el-table-column fixed="right" min-width="70">
              <div slot-scope="{ row }" class="table-actions">
                <!--<el-tooltip
                  :content="$t('COMMON.VIEW')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_VIEW_RESELLERS)"
                >
                  <a
                    type="text"
                    @click="viewReseller(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-expand-alt"></i>
                  </a>
                </el-tooltip>
                <el-tooltip
                  :content="$t('COMMON.EDIT')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_EDIT_RESELLERS)"
                >
                  <a
                    type="text"
                    @click="editReseller(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-edit"></i>
                  </a>
                </el-tooltip>
                <el-tooltip
                  :content="$t('COMMON.DELETE')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_DELETE_RESELLERS)"
                >
                  <a
                    type="text"
                    @click="deleteReseller(row)"
                    class="table-action table-action-delete"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-trash-alt"></i>
                  </a>
                </el-tooltip>-->
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link">
                    <i class="far fa-ellipsis-v"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="actions-dropdown">
                    <el-dropdown-item
                      v-if="$currentUserCan($permissions.PERM_VIEW_RESELLERS)"
                    >
                      <a
                        type="text"
                        @click="viewReseller(row)"
                        class="table-action view"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img
                            src="../../../../../../public/img/kw-eye.svg"
                            alt="icon"
                          />
                        </span>
                        <span class="text">{{
                          $t("COMMON.VIEW_DETAILS")
                        }}</span>
                      </a>
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="$currentUserCan($permissions.PERM_EDIT_RESELLERS)"
                    >
                      <a
                        type="text"
                        @click="editReseller(row)"
                        class="table-action edit"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img
                            src="../../../../../../public/img/kw-edit.svg"
                            alt="icon"
                          />
                        </span>
                        <span class="text">
                          {{ $t("COMMON.EDIT") }}
                        </span>
                      </a>
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="$currentUserCan($permissions.PERM_DELETE_RESELLERS)"
                    >
                      <a
                        type="text"
                        @click="deleteReseller(row)"
                        class="table-action delete"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img
                            src="../../../../../../public/img/kw-delete.svg"
                            alt="icon"
                          />
                        </span>
                        <span class="text">
                          {{ $t("COMMON.DELETE") }}
                        </span>
                      </a>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div
        v-if="resellers.length > 0"
        slot="footer"
        class="page-wrapper-table-footer"
      >
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
    <div v-if="resellers.length === 0" class="no-data">
      <div class="no-data-inner">
        <img src="../../../../../../public/img/kw-empty-box.svg" alt="icon" />
        <p>{{ $t("RESELLERS.NO_DATA") }}</p>
        <base-button
          class="kw-button add"
          icon
          size="sm"
          @click="onAddReseller"
          v-if="$currentUserCan($permissions.PERM_CREATE_RESELLERS)"
        >
          <span class="btn-inner--icon">
            <i class="far fa-plus"></i>
          </span>
          <span class="btn-inner--text">{{
            $t("RESELLERS.ADD_RESELLER")
          }}</span>
        </base-button>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { BasePagination } from "@/components";

export default {
  layout: "DashboardLayout",

  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [],

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      resellers: [],
      loading: true,
    };
  },
  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },
  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { name: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "owner",
        };

        await this.$store.dispatch("resellers/list", params);
        this.resellers = this.$store.getters["resellers/list"];
        this.total = this.$store.getters["resellers/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    deleteReseller(reseller) {
      this.$emit("onDeleteReseller", reseller);
    },

    onAddReseller() {
      this.$emit("onAddReseller");
    },

    viewReseller(reseller) {
      this.$emit("onViewReseller", reseller);
    },

    editReseller(reseller) {
      this.$emit("onEditReseller", reseller);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
